<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
        >
          <v-img
            v-if="this.$cookie.get('website_id') === '1'"
            src="https://www.espace-jantes.com/ej-favicon.png"
          />
          <v-img
            v-if="this.$cookie.get('website_id') === '2'"
            src="https://www.distri-jantes.com/dj-favicon.svg"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      favicon: '',
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/',
        },
        {
          title: 'commandes',
          icon: 'mdi-cart-outline',
          to: '/commande/list-commande',
        },
        {
          title: 'Formulaires',
          icon: 'mdi-email',
          to: '/formulaire/list-formulaire',
        },
        {
          title: 'jantes',
          icon: 'mdi-buddhism',
          to: '/product/list-jantes',
        },
        // {
        //   title: 'Vehicules',
        //   icon: 'mdi-car',
        //   to: '/vehicule/list-vehicule',
        // },
        // {
        //   title: 'Pneus',
        //   icon: 'mdi-album',
        //   to: '/product/list-pneu',
        // },
        {
          title: 'utilisateurs',
          icon: 'mdi-account-group',
          to: '/users/list-users',
        },
        {
          title: 'promotions',
          icon: 'mdi-cash-multiple',
          to: '/promotions/promotion',
        },
        {
          title: 'paramètres',
          icon: 'mdi-cogs',
          to: '/parametres/parametre',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          // title: this.$t('site_title'),
          title: this.$cookie.get('website_id') === '1' ? 'Espace Jantes' : 'Distri Jantes',
        }
      },
    },
    mounted () {
      this.favicon = this.$cookie.get('website_id') === '1' ? 'https://www.espace-jantes.com/wp-content/uploads/2017/11/favicon.png' : 'https://pbs.twimg.com/profile_images/1090627769103470592/WCY3rLGl_400x400.jpg'
      // 'https://pbs.twimg.com/profile_images/1090627769103470592/WCY3rLGl_400x400.jpg'
      // 'https://www.espace-jantes.com/wp-content/uploads/2017/11/favicon.png'
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
